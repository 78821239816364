import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Logo from "./logo"

const Header = () => {

    const data = useStaticQuery(
        graphql`
        query {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
        `
      )

    return (
        <header> 
            
            <nav className="navbar is-fixed-top"  role="navigation" aria-label="main navigation">
                
            <div className="navbar-menu">
                    <div className="navbar-end">       
                        <Link className="navbar-item is-uppercase has-text-weight-bold is-italic" to="#about">Chi siamo</Link>
                        <Link className="navbar-item is-uppercase has-text-weight-bold is-italic" to="#stats">Cosa facciamo</Link>        
                        
                    </div>
                </div>
                
                <div className="navbar-brand">
                    <div className="mx-6 my-2">
                      <Link to="/">
                        <Logo></Logo>
                        <h1 className="title is-4 is-uppercase has-text-weight-bold is-italic mt-2 mb-1">{data.site.siteMetadata.title}</h1>
                        <h6 className="title is-7 is-uppercase has-text-weight-semibold has-text-centered is-italic">{data.site.siteMetadata.description}</h6>
                      </Link> 
                    </div>

                    {/* <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    </a> */}
                </div>

                <div className="navbar-menu">
                    <div className="navbar-start">       
                        <Link className="navbar-item is-uppercase has-text-weight-bold is-italic" to="#jobs">Lavora con noi</Link>  
                        <Link className="navbar-item is-uppercase has-text-weight-bold is-italic" to="#contacts">Contatti</Link>         
                        
                    </div>
                </div>
            </nav> 
        </header>  )
}

export default Header