import React from "react"
import Header from "./header"
import Footer from "./footer"
import "../styles/style.scss"
import "@fontsource/montserrat"
import "@fontsource/montserrat/100.css"
import "@fontsource/montserrat/100-italic.css"
import "@fontsource/montserrat/200.css"
import "@fontsource/montserrat/200-italic.css"
import "@fontsource/montserrat/300.css"
import "@fontsource/montserrat/300-italic.css"
import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/400-italic.css"
import "@fontsource/montserrat/500.css"
import "@fontsource/montserrat/500-italic.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/600-italic.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/700-italic.css"
import "@fontsource/montserrat/800.css"
import "@fontsource/montserrat/800-italic.css"
import "@fontsource/montserrat/900.css"
import "@fontsource/montserrat/900-italic.css"

const Layout = props => {
  return (
    <div className="has-navbar-fixed-top">
      <Header />
      
      {props.children}

      <Footer />
    </div>
  )
}

export default Layout