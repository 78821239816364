import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker, faPhone, faEnvelopeOpen} from '@fortawesome/free-solid-svg-icons'

const Footer = () => {

  const data = useStaticQuery(
    graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          copyright
          contacts {
            address
            cap
            city
            fax
            mail
            phone
            state
          }
        }
      }
    }
    `
  )

  return (
    <footer className="footer">

      <div className="columns">
        <div className="column is-half mb-6">
          <div className="has-text-centered">
            <figure className="image is-4by3 pt-1 px-6">
              <StaticImage 
              src="../images/logo_blue.png" 
              alt="3M logo"
              placeholder="blurred"
              width={141}
              height={78} 
              />
            </figure>
            <h1 className="title is-4 is-uppercase has-text-weight-bold is-italic mt-2 mb-1">{data.site.siteMetadata.title}</h1>
                        <h6 className="title is-7 is-uppercase has-text-weight-semibold has-text-centered is-italic">{data.site.siteMetadata.description}</h6>
          </div>
        </div>
        <div className="column  mb-6">
          <div className="content">
            <div className="icon-text">
                    <span className="icon">
                        <FontAwesomeIcon icon={faMapMarker} />
                    </span>
                    <span>{data.site.siteMetadata.contacts.address}</span>
                    
            </div>
            <p className="block ml-5">
                {data.site.siteMetadata.contacts.cap}
                <br></br>
                {data.site.siteMetadata.contacts.city}
                <br></br>
                {data.site.siteMetadata.contacts.state}
            </p>
          </div>
          

        </div>
        <div className="column  mb-6">
          <div className="content">
            <div className="icon-text">
              <span className="icon">
                  <FontAwesomeIcon icon={faPhone} />
              </span>
              <span>{data.site.siteMetadata.contacts.phone}</span>
              
            </div>

            <div className="icon-text">
              <span className="icon">
                  <FontAwesomeIcon icon={faEnvelopeOpen} />
              </span>
              <span>{data.site.siteMetadata.contacts.mail}</span>
              
            </div>
          </div>

        </div>
      </div>

      <div className="content has-text-centered">
            <p className="is-size-7 mb-0 pt-5">
              Copyright {data.site.siteMetadata.copyright} &copy; 2020 - {new Date().getFullYear().toString()}{" "}
            </p>
            <span className="is-size-7 has-text-weight-light">Made by {data.site.siteMetadata.author} </span>
          </div>
      
          <a href="https://www.iubenda.com/privacy-policy/99568924/cookie-policy" 
         className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe" 
         title="Cookie Policy">
        Cookie Policy
      </a>
      

    </footer>
  )
}

export default Footer