import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Logo = () => {
 return(
   <figure class="image is-4by3 pt-1 px-6">

      <StaticImage 
      src="../images/logo_white.png" 
      alt="3M logo"
      placeholder="blurred"
      width={71}
      height={40} 
      />
    </figure>
 ) 
}

export default Logo